body {
  min-height: 100vh!important;
  background-color: #fff!important;
  margin: 0;
  font-family: 'gotham', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif!important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
img {
  max-width: 100%;
}
a {
  text-decoration: none;
}

:root {
  --main-blue-dark: #0E3F67;
  --main-blue: #008FFD;
  --main-blue-light: #CCE9FF;

  /* user colors */
  --user-primary: #fff;
  --user-secondary: #cdcdcd;
  --user-black: #000;
  --user-black-light: #504f4f;
  --user-gray: gray;
  --user-gray-light: gainsboro;

  /* headers */
  --header-primary:gray;
  --header-primary-text: white;
  --header-secondary:white;
  --header-secondary-color: #000;
  
  /* search bar */
  --search-color: gray;

  /* backgrounds */
  --background: #fafafa;
  --background-secondary: white;

  /* text */
  --page-title: black;
  --primary: black;
  --paragraph: black;
  --empty-messages: #cce9ff;
}
* {
  box-sizing: border-box;
}
.paypal-button {
  display: none;
}

/* hide openpay's iframe */
#_op_data_antifraud {
  display: none;
}
*::-webkit-scrollbar {
  display: none;
}